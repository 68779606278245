import React from 'react';
import { graphql } from 'gatsby';
import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '@components/pageMeta';
import Layout from '@components/layout';
import { SanityImage } from '@components/sanity/sanityImage';
import CardCarousel from '@components/pageModules/modules/cardCarousel';
import { useProjectsQuery } from '../GraphQl/useProjectsQuery';
import ModuleZone from '~moduleZone';
import StandardButton from '@components/UI/standardButton';
import loadable from '@loadable/component';
import { wrapper } from '../css/base/backgroundVideo.module.css';

const Player = loadable(() => import('react-player'));

const ProjectTemplate = ({ data: { page } }) => {
  const {
    seo,
    title,
    bannerImage,
    bannerVideo,
    isBannerVideo,
    modules,
    serviceRef,
    locationRef,
  } = page || {};
  const { nodes } = useProjectsQuery();

  const filteredProjects = nodes?.filter((node) => node?.title !== title);

  return (
    <Layout reverseHeader={false}>
      <PageMeta {...seo} />
      <div>
        <div
          className='h-[50vh] md:h-screen w-full relative flex items-center justify-start text-white '
          id='top'>
          {!isBannerVideo ? (
            <SanityImage
              image={bannerImage}
              className='absolute z-0 w-full h-full top-0 left-0 object-cover'
            />
          ) : (
            <div
              className={`absolute z-0 w-full h-full top-0 left-0 object-cover md:fixed  md:h-screen   ${wrapper}`}>
              <Player
                url={bannerVideo}
                playing
                // onPlay={() => setWait(false)}
                loop
                playsinline
                muted
                className=' w-full h-full top-0 left-0 object-cover'
                width='100%'
                height='100%'
              />
            </div>
          )}
          <div className='absolute top-0 left-0 w-full h-full bg-black bg-opacity-60' />
          <div className='hidden md:block relative z-10 px-gutter w-[70vw]'>
            <h2 className=' blockH6 pt-8 pb-3  '>
              {serviceRef?.title || locationRef?.locationName}
            </h2>
            <h1 className='blockH2 pb-5 '>{title}</h1>

            <StandardButton buttonName='Read more' />
          </div>
        </div>
        <div className='md:hidden  relative z-10 px-gutter w-full h-[50vh]'>
          <h2 className=' blockH6 pt-8 pb-3  '>
            {serviceRef?.title || locationRef?.locationName}
          </h2>
          <h1 className='blockH2 pb-5 '>{title}</h1>
        </div>
      </div>
      <div className='bg-[#E5F6FB] relative'>
        {modules && <ModuleZone {...modules} />}
      </div>
      <div className='bg-light-blue pt-space-m pb-space-m'>
        <CardCarousel
          items={filteredProjects}
          heading='Other Projects'
          path={'projects'}
        />
      </div>
    </Layout>
  );
};

export default ProjectTemplate;

export const pageQuery = graphql`
  query standardProjectQuery($slug: String!) {
    page: sanityProject(slug: { current: { eq: $slug } }) {
      seo {
        ...SEO
      }
      slug {
        current
      }
      bannerImage {
        ...ImageWithPreview
      }
      bannerVideo
      isBannerVideo
      title
      modules {
        ...ProjectModules
      }
      locationRef {
        locationName
      }
      serviceRef {
        title
      }
    }
  }
`;
