import { useStaticQuery, graphql } from 'gatsby';

import { QueryFragments } from './queryFragments'; // eslint-disable-line

export const useProjectsQuery = () => {
  const { allSanityProject } = useStaticQuery(graphql`
    query {
      allSanityProject(sort: { fields: orderRank, order: ASC }) {
        nodes {
          title
          slug {
            current
          }

          bannerImage {
            ...ImageWithPreview
          }
          locationRef {
            locationName
          }
          industryRef {
            industryName
          }
          serviceRef {
            title
          }
        }
      }
    }
  `);
  return allSanityProject || {};
};
